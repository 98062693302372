import Immobilie from '@/models/immobilie.model';
import { Coordinates, GeoJSONSource, LngLatBounds } from 'maplibre-gl';
import { onMounted } from 'vue';

export function usePropertyMap(properties: Immobilie[]) {
  //Variables
  let map = null as any;
  let propertySource: any;
  let myPropertyList: Immobilie[] = properties;

  //Lifecycle Methods
  onMounted(() => {
    setPropertySource();
  });

  //Methods
  function setPropertySource(propertyId?: number ): void {
    const workItemsGeoJSONPoints = myPropertyList.filter(prop => prop.geolocationLon && prop.geolocationLat).map((property: Immobilie) => {
      if (property.geolocationLat !== null) {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [property.geolocationLon, property.geolocationLat],
          },
          properties: {
            itemData: property.id,
            selected: propertyId === property.id
          },
        };
      }
    });

    propertySource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: workItemsGeoJSONPoints,
      }
    };
  }

  function addPropertySource(): void{
    map.addSource('propertySource', propertySource)
  }


  function addPropertyLayer(): void {
    map.addLayer({
      id: 'property-points',
      type: 'circle',
      source: 'propertySource',
      paint: {
        'circle-radius': 4,
        "circle-stroke-width": 2.5,
        'circle-color': [
            'case',
            ['==', ['get', 'selected'], true], '#BADEF2',
            '#511A3F'
        ],
        "circle-opacity": 0,
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'selected'], true], '#BADEF2','#511A3F'
          
      ]
      }
    });
  }
  
  function getBounds(): LngLatBounds {
    const coordinates: Coordinates = propertySource.data.features.map((feature: any) => {
      return feature.geometry.coordinates;
    });

    const bounds: LngLatBounds = coordinates.reduce((bounds: any, coord: any) => {
        return bounds.extend(coord);
    }, new LngLatBounds(coordinates[0], coordinates[0]));

    return bounds;
  }

  async function animateToProperty(property: Immobilie): Promise<void> {
    if(map){
      const source: GeoJSONSource = map.getSource('propertySource') as GeoJSONSource;
      setPropertySource(property.id);
      source.setData(propertySource.data); 
      map.flyTo({
        center: [property.geolocationLon, property.geolocationLat],
        zoom: 17.5,
        minZoom: 13,
        speed: 0.5,
        essential: true
      });
    }
  }

  function updateProperties(props: Immobilie[]): void {
    myPropertyList = props;
    setPropertySource();
    if(map){
      (map.getSource('propertySource') as GeoJSONSource).setData(propertySource.data);
    }
  }

  function reload(): void {
    console.log('reloading')
    map.resize();
  }

  const generateMap = (mapObject: any, mapconfig: any) => {
    map = mapObject;    
    addPropertySource();
    addPropertyLayer();
    zoomToOverview(mapconfig);
  }

  const zoomToOverview = (mapconfig: any) => {
    if(map){
      if(propertySource?.data.features.length > 1) {
        map.fitBounds(getBounds(), { padding: 150 });
      } else if(propertySource?.data.features.length === 1){
        map.jumpTo({
          center: [propertySource.data.features[0].geometry.coordinates[0], propertySource.data.features[0].geometry.coordinates[1]],
          zoom: 17.5
        });
      }else{
        map.jumpTo({ // initial view
          center: mapconfig?.mapCenter || mapconfig?.mapStyle?.mapCenter || [16.363449, 48.210033],
          zoom: mapconfig?.mapInitialZoomLevel || mapconfig?.mapStyle?.mapInitialZoomLevel || 17
        });
      }
    }
  }

  return {
    animateToProperty,
    updateProperties,
    reload,
    generateMap,
    zoomToOverview
  };
}
