import useAlert from "@/composables/useAlert";
import useReduceImageSize, {
  ReduceFileSizeSettings,
} from "@/composables/useReduceImageSize";
import { useStore } from '@/composables/useTypedStore';
import { APhoto } from "@/models/photo/a-photo.model";
import CachedPhoto from "@/models/photo/cached-photo.model";
import { getPhotoUrl, isStrapiPhoto } from "@/utilities/get-media-url";
import {
  b64toBlob,
  base64ImgToBlob,
  convertBlobToBase64,
} from '@/utilities/photo-storage';
import { Media, MediaSaveOptions } from '@capacitor-community/media';
import {
  Camera,
  CameraResultType,
  CameraSource
} from "@capacitor/camera";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import { isPlatform } from "@ionic/vue";
import { computed } from "vue";
import {
  addMetadataToJpeg,
  createExifMetaData,
} from './Utilities/jpegMetadata.js';

export interface SelectPhotoResponse {
  success: boolean,
  data: CachedPhoto[] | undefined,
  messageKey?: "portraitOnly"
}

export function usePhotoRecording() {
  const { reduceFileSize, blobToFile, getFileFromWebpath } =
    useReduceImageSize();

  const getCompressedFiles = async (
    path: string,
    settings: ReduceFileSizeSettings,
    format = "jpeg"
  ): Promise<SelectPhotoResponse> => {
    try {
      // console.log("CAMERA PHOTO", cameraPhoto, file);

      const file = await getFileFromWebpath(path);

      return new Promise<SelectPhotoResponse>((resolve, reject) => {
        try {
          console.log("Reducing file size... ");
          reduceFileSize(
            file,
            400 * 1024,
            1000,
            Infinity,
            0.9,
            settings,
            async (blob: Blob | false) => {
              if (!blob) {
                resolve({ success: false, data: undefined });
                return;
              }

              let path = '';
              const uuid = generateUUID();
              const randomName = uuid + '.' + format;

              const exifMetaData = await createExifMetaData(
                useStore().state.currentHzba.currentBa
              );

              const newImage = await addMetadataToJpeg(
                await convertBlobToBase64(blob),
                exifMetaData
              );

              blob = await base64ImgToBlob(newImage);
              const url = URL.createObjectURL(blob);

              const savedFile = await Filesystem.writeFile({
                path: randomName,
                data: newImage as string,
                directory: Directory.Data,
              });
              path = savedFile.uri;

              console.log("Save compressed file", savedFile);
              // }

              resolve({
                success: true,
                data: [
                  {
                    filepath: undefined,
                    format: format,
                    webPath: url,
                    uid: generateUUID(),
                    path: path,
                  },
                ],
              });
            }
          );
        } catch (err) {
          console.error(
            "Error while taking a photo and reducing file size",
            err
          );
        }
      });
    } catch (err) {
      console.error("Error while taking a photo!", err);
      return new Promise<SelectPhotoResponse>((resolve, reject) => {
        resolve({
          success: false,
          data: undefined,
        });
      });
    }
  };

  const getAlbumId = async () => {
    try {
      const findAlbum = async (name: string) => {
        const albumRes = await Media.getAlbums();
        return albumRes.albums.find((item) => {
          console.log("get Album ID: ", item.name);
          return item.name == name;
        });
      };

      let album = await findAlbum("Acon 360°");
      if (!album) {
        await Media.createAlbum({ name: "Acon 360°" });

        album = await findAlbum("Acon 360°");
      }

      return album && (isPlatform("ios") ? album.identifier : album.name);
    } catch (err) {
      console.log("Could not find/create album", err);
    }
  };

  const takePhoto = async (
    settings: ReduceFileSizeSettings
  ): Promise<SelectPhotoResponse> => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });

      const compressedRes = await getCompressedFiles(
        cameraPhoto.webPath!,
        settings,
        cameraPhoto.format
      );
      const image =
        compressedRes.success &&
        compressedRes.data &&
        compressedRes.data.length > 0 &&
        compressedRes.data[0];
      console.log("Image vs CompressedImage", image, compressedRes);

      if (image && image.path) {
        const opt: MediaSaveOptions = {
          path: image.path,
          albumIdentifier: await getAlbumId(),
        };
        Media.savePhoto(opt)
          .then((e: any) => console.log("photo saved.")) // on android it returns {filePath: 'uri to media'}
          .catch(console.log);
      } else {
        console.error("Image is empty, could not write to system storage.");
        return {
          success: false,
          data: undefined,
          messageKey: "portraitOnly",
        };
      }

      return compressedRes;
    } catch (err) {
      console.error("Error while taking a photo!", err);
      return {
        success: false,
        data: undefined,
      };
    }
  };

  const choosePhotoFromGallery = async (
    limit?: number,
    settings?: ReduceFileSizeSettings
  ): Promise<SelectPhotoResponse> => {
    let reportError = false;

    try {
      const params = limit && limit > 0 ? { limit: limit } : {};
      const res = await Camera.pickImages(params);
      const images =
        limit && limit > 0 ? res.photos.slice(0, limit) : res.photos;
      const compressedFiles: CachedPhoto[] = [];

      for (let i = 0; i < images.length; i++) {
        const compressedImage = await getCompressedFiles(
          images[i].webPath!,
          settings || {},
          images[i].format
        );
        if (compressedImage.success) {
          compressedImage.data && compressedFiles.push(...compressedImage.data);
        } else {
          // console.error('Error when compressing files', compressedImage);
          // return { success: false, data: undefined }
          reportError = true;
        }
      }

      console.log("Choose images....", reportError);
      return {
        data: compressedFiles,
        success: true,
        messageKey: reportError ? "portraitOnly" : undefined,
      };
    } catch (err) {
      console.error("Error while taking a photo!", err);
      return {
        success: false,
        data: undefined,
      };
    }
  };

  const pickFiles = async () => {
    const result = await FilePicker.pickFiles({
      types: ["image/png"],
      multiple: true,
    });
  };

  const appendFileToFormData = async ( isPdfUploadType = false ): Promise<SelectPhotoResponse> => {
    const pickFilesConfig: any = { multiple: true, readData: true };
    if ( isPdfUploadType ) {
      pickFilesConfig["types"] = ["application/pdf"];
    }
    const result = await FilePicker.pickFiles(pickFilesConfig);
    const file = result.files[0];
    // const file = await fetch(result.files[0].path)
    console.log('result from FilePicker: ', result);
    console.log('result.files[0] from FilePicker: ', result.files[0]);

    // const savedFile = await Filesystem.writeFile({
    //   path: file.name,
    //   data: file.data as string,
    //   directory: Directory.Data
    // });

    // console.log('savedFile:', savedFile);
    const isMobile = computed(() => {
      return (isPlatform("ios") || isPlatform("ipad") || isPlatform("android"));
    });

    console.log('IS MOBILE ?', isMobile.value);
    const uploadType = file.name.split('.').pop();
    if (!file.blob && file.data && isMobile.value) {
      const randomName =
        Math.random().toString(36).substr(2, 12) + `.${uploadType}`;

      const savedFile = await Filesystem.writeFile({
        path: randomName,
        data: file.data as string,
        directory: Directory.Documents
      });

      const path = savedFile.uri;
      const pdfUrl = URL.createObjectURL(b64toBlob(file.data, `application/${uploadType}`));
      const retVal = {
        success: true,
        data: [
          {
            filepath: file.name,
            format: uploadType,
            webPath: pdfUrl,
            uid: generateUUID(),
            path: path
          },
        ],
      };
      return retVal;
    }

    if (!file.blob) {
      return { success: false, data: undefined };
    }

    const savedFile = await Filesystem.writeFile({
      path: file.name,
      data: (await convertBlobToBase64(file.blob)) as string,
      directory: Directory.Data,
    });

    const path = savedFile.uri;

    const blobUrl = URL.createObjectURL(file.blob);

    const retVal = {
      success: true,
      data: [
        {
          filepath: undefined,
          format: uploadType,
          webPath: blobUrl,
          uid: generateUUID(),
          path: path,
        },
      ],
    };

    // console.log("Save pdf blob", blobUrl);
    // console.log("Save pdf blob", file.blob);
    // console.log("Save pdf", savedFile)
    // console.log("Save pdf", retVal)
    // }

    return retVal;

    //
    // console.log("append file to form data", formData, file);
    //
    // // @ts-ignore
    // const rawFile = new File(file.blob, file.name, {
    //     type: file.mimeType,
    // });
    // formData.append('file', rawFile, file.name);
  };

  const openPhotoDialog = (
    t: any,
    limit?: number,
    showOptions?: {
      hideFile?: boolean;
      hidePhoto?: boolean;
      hideGallery?: boolean;
      allowPortraitOnly?: boolean;
      isPdfUploadType?: boolean;
    },
  ): Promise<SelectPhotoResponse> => {
    const alert = useAlert();

    return new Promise<SelectPhotoResponse>((resolve, reject) => {
      if (showOptions?.hidePhoto && showOptions?.hideGallery) {
        return resolve(appendFileToFormData( showOptions.isPdfUploadType));
      }

      const buttons = [];
      if (!showOptions || !showOptions.hidePhoto) {
        buttons.push({
          text: t("alerts.selectTakePhoto"),
          handler: async () => {
            resolve(
              await takePhoto({
                allowPortraitOnly: showOptions?.allowPortraitOnly,
              })
            );
          },
        });
      }
      if (!showOptions || !showOptions.hideGallery) {
        buttons.push({
          text: t("alerts.selectUseGallery"),
          handler: async () => {
            resolve(
              await choosePhotoFromGallery(limit, {
                allowPortraitOnly: showOptions?.allowPortraitOnly,
              })
            );
          },
        });
      }
      if (!showOptions || !showOptions.hideFile) {
        buttons.push({
          text: "Datei wählen",
          handler: async () => {
            // @ts-ignore
            resolve(await appendFileToFormData( showOptions.isPdfUploadType ));
          },
        });
      }
      buttons.push({
        text: t("alerts.abort"),
        handler: async () => {
          resolve({ success: false, data: undefined });
        },
      });

      alert
        .showAdv("Bitte auswählen...", "", buttons, {
          backdropDismiss: false,
        })
        .then();
    });
  };

  const duplicatePhoto = (photo: APhoto): Promise<SelectPhotoResponse> => {
    if (isStrapiPhoto(photo)) {
      console.warn("STrapi photos not supported yet.");

      return getCompressedFiles(getPhotoUrl(photo)!, {
        allowPortraitOnly: true,
      });
      // return new Promise<SelectPhotoResponse>((resolve, reject) => {
      //     resolve({ success: false, data: undefined});
      // })
    } else {
      const cachedPhoto = photo as CachedPhoto;
      return getCompressedFiles(
        cachedPhoto.webPath!,
        { allowPortraitOnly: true },
        cachedPhoto.format!
      );
    }
  };

  return { takePhoto, choosePhotoFromGallery, openPhotoDialog, duplicatePhoto };
}

/**
 *
 * Without reduced file size this function would look like this:
 *
 * const takePhoto = async (): Promise<CachedPhoto> => {
        const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
        });

        return {
            filepath: undefined,
            format: cameraPhoto.format,
            webPath: cameraPhoto.webPath,
            path: cameraPhoto.path,
        };
    };
 */
